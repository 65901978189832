import React from 'react';

interface PageContainerProps {
  children: React.ReactNode;
}

export function PageContainer({ children }: PageContainerProps) {
  return (
    <main className="relative min-h-screen">
      <div className="relative z-10 max-w-2xl mx-auto px-4 py-12 space-y-12">
        {children}
      </div>
    </main>
  );
}