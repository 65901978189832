import React from 'react';
import { PageLayout } from '../components/layout/PageLayout';
import { SpeakingHero } from '../components/speaking/SpeakingHero';
import { SpeakingContent } from '../components/speaking/SpeakingContent';
import { ContactButton } from '../components/speaking/ContactButton';

export function Speaking() {
  return (
    <PageLayout>
      <div className="space-y-12">
        <div className="text-center space-y-2">
          <h1 className="text-5xl font-bold bg-gradient-to-r from-[#1A8F86] to-[#B08E2A] bg-clip-text text-transparent">
            Transform Your Event
          </h1>
          <p className="text-2xl font-semibold text-primary-dark">
            Inspire, educate, and empower your audience
          </p>
        </div>
        <SpeakingHero />
        <SpeakingContent />
        
        <div className="text-center">
          <ContactButton />
        </div>
      </div>
    </PageLayout>
  );
}