import React from 'react';

export function SpeakingContent() {
  return (
    <div className="prose prose-lg max-w-none space-y-6 backdrop-blur-sm bg-white/50 p-8 rounded-xl shadow-[0_20px_50px_rgba(8,_112,_184,_0.7)]">
      <p>
        With decades of experience as a coach, innovator, and entrepreneur, Jenn knows how to connect with diverse audiences—whether they're seasoned professionals or aspiring go-getters. Her expertise spans <strong>AI-driven solutions</strong> and <strong>book publishing strategies</strong>, showing your audience how to amplify their brands, streamline their businesses, and unlock new opportunities.
      </p>

      <p>
        When Jenn takes the stage, she does more than deliver a talk—she inspires a movement. Your audience will leave with fresh perspectives, tangible tools, and the motivation to take bold steps toward their goals. If you want a speaker who will not only educate but also ignite a sense of purpose and action, Jenn is your perfect choice.
      </p>

      <p className="font-bold text-xl text-center">
        Let's make your event unforgettable—reach out to our team today to enquire about availability and discuss your event!
      </p>
    </div>
  );
}