import React from 'react';
import { UserCircle } from 'lucide-react';
import { Link } from 'react-router-dom';

export function AboutButton() {
  return (
    <Link
      to="/about"
      className="block w-full bg-[#C0C0C0] text-black border border-black rounded-lg px-6 py-4 text-center font-medium hover:scale-[1.02] transition-all duration-300 hover:shadow-lg flex items-center justify-center gap-3 mt-4"
    >
      <UserCircle size={24} />
      <span>About AI Tech Boss Jenn</span>
    </Link>
  );
}