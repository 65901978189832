import React from 'react';
import { ArrowRight } from 'lucide-react';

export function ContactButton() {
  return (
    <a
      href="https://link.springboardcrm.com/widget/form/UP6yulViLHcG5pLpivNG"
      target="_blank"
      rel="noopener noreferrer"
      className="inline-flex items-center gap-3 px-8 py-4 bg-gradient-to-r from-accent-turquoise to-accent-gold text-white rounded-lg font-medium shadow-[0_8px_30px_rgb(0,0,0,0.12)] hover:shadow-[0_8px_30px_rgba(0,0,0,0.25)] transition-all duration-300 hover:scale-[1.02]"
    >
      <span>Click here to Inquire</span>
      <ArrowRight size={24} />
    </a>
  );
}