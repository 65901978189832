import React from 'react';

export function PersonalInfo() {
  return (
    <div className="space-y-6 prose prose-lg max-w-none">
      <div className="flex flex-col md:flex-row gap-8">
        {/* Left column with initial text */}
        <div className="w-full md:w-1/2">
          <h2 className="text-2xl font-semibold">Here are a few fun facts...</h2>
          
          <p className="text-xl font-medium">
            Canadian girl turned New Yorker with Trinidadian and Irish heritage.
          </p>

          <p>
            Beyond her professional endeavors, Jennifer is a devoted wife and mother, cherishing every moment spent with her family in the vibrant city of New York.
          </p>
        </div>
        
        {/* Right column with image */}
        <div className="w-full md:w-1/2">
          <img
            src="https://storage.googleapis.com/msgsndr/SH9l7Q6IvahKRX1CGcWJ/media/6757cc3c988a5feb8fa2ba70.png"
            alt="Personal photos collage"
            className="rounded-lg shadow-lg w-full h-auto"
          />
        </div>
      </div>

      {/* Full width content */}
      <div>
        <p>
          As a Director at a private school in the heart of the city, Jennifer brings her passion for education to life, shaping the minds of future leaders and innovators. Her role as an educator fuels her commitment to empowering others, both in the classroom and beyond.
        </p>

        <p>
          In her downtime, Jennifer finds solace in the simple joys of life – whether it's exploring the city's bustling streets with her loved ones, indulging in a good book, or immersing herself in creative pursuits. She also is a lover of all things Carnival, embracing her West Indian culture.
        </p>

        <p>
          She believes in the importance of balance and self-care, prioritizing moments of relaxation and rejuvenation amidst her busy schedule.
        </p>

        <p>
          Jennifer's personal journey is a testament to the power of resilience, perseverance, and the unwavering belief in the beauty of life's journey.
        </p>
      </div>
    </div>
  );
}