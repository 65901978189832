import React from 'react';

interface ContainerProps {
  children: React.ReactNode;
  className?: string;
}

export function Container({ children, className = '' }: ContainerProps) {
  return (
    <div className={`backdrop-blur-sm bg-white/50 p-8 rounded-xl shadow-[0_20px_50px_rgba(8,_112,_184,_0.7)] ${className}`}>
      {children}
    </div>
  );
}