import React from 'react';

export function BackgroundImage() {
  return (
    <div className="fixed inset-0 z-0">
      <div 
        className="absolute inset-0 bg-[url('https://storage.googleapis.com/msgsndr/SH9l7Q6IvahKRX1CGcWJ/media/675a5a703772bdadca835abd.png')] bg-cover bg-center opacity-40"
        style={{
          maskImage: 'radial-gradient(circle at center, black 40%, transparent 80%)',
          WebkitMaskImage: 'radial-gradient(circle at center, black 40%, transparent 80%)'
        }}
      />
      <div className="absolute inset-0 bg-gradient-to-b from-white/60 via-transparent to-transparent" />
    </div>
  );
}