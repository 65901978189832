import React from 'react';
import { Link } from 'react-router-dom';
import { ArrowLeft } from 'lucide-react';
import { BackgroundImage } from '../components/BackgroundImage';
import { PageContainer } from '../components/PageContainer';

export function GPTShop() {
  return (
    <>
      <BackgroundImage />
      <PageContainer>
        <Link to="/" className="inline-flex items-center gap-2 text-gray-600 hover:text-black mb-8">
          <ArrowLeft size={20} />
          <span>Back to Home</span>
        </Link>

        <div className="space-y-8 backdrop-blur-sm bg-white/50 p-8 rounded-xl shadow-[0_20px_50px_rgba(8,_112,_184,_0.7)]">
          <div className="flex flex-col items-center gap-6">
            <img
              src="https://storage.googleapis.com/msgsndr/SH9l7Q6IvahKRX1CGcWJ/media/675a65713772bd4ef08361f4.png"
              alt="GPT Pro Shop Logo"
              className="w-48 h-auto"
            />
            <h1 className="text-4xl font-bold text-center bg-gradient-to-r from-[#1A8F86] to-[#B08E2A] bg-clip-text text-transparent">
              GPT Pro Shop
            </h1>
            
            <div className="mt-4 text-center">
              <p className="text-2xl font-bold text-primary-dark animate-pulse">
                Coming Soon! New Years Drop!
              </p>
            </div>
          </div>
        </div>
      </PageContainer>
    </>
  );
}