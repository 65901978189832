import React from 'react';
import { VideoPlayer } from './VideoPlayer';

export function SpeakingHero() {
  return (
    <div className="flex flex-col md:flex-row gap-8 items-stretch">
      <div className="w-full md:w-1/2 flex">
        <div className="prose prose-lg backdrop-blur-sm bg-white/50 p-8 rounded-xl shadow-[0_20px_50px_rgba(8,_112,_184,_0.7)] flex flex-col justify-center">
          <p>
            Looking to captivate, inspire, and empower your audience? <strong>AI Tech Boss Jenn</strong> is the speaker you've been searching for. As a dynamic thought leader at the intersection of creativity, technology, and entrepreneurship, Jenn delivers engaging, actionable insights that transform how we think about growing businesses and making an impact.
          </p>
        </div>
      </div>

      <div className="w-full md:w-1/2">
        <VideoPlayer />
      </div>
    </div>
  );
}