import React from 'react';
import { Linkedin, Instagram, Facebook } from 'lucide-react';

export function SocialLinks() {
  return (
    <div className="flex justify-center gap-4">
      <a 
        href="https://www.linkedin.com/in/aitechbossjenn/" 
        target="_blank" 
        rel="noopener noreferrer" 
        className="p-3 rounded-full bg-primary-light hover:bg-accent-turquoise hover:text-white hover:scale-110 transition-all duration-300 hover:shadow-glow"
      >
        <Linkedin size={24} />
      </a>
      <a 
        href="https://www.instagram.com/stories/aitechbossjenn/" 
        target="_blank" 
        rel="noopener noreferrer" 
        className="p-3 rounded-full bg-primary-light hover:bg-accent-turquoise hover:text-white hover:scale-110 transition-all duration-300 hover:shadow-glow"
      >
        <Instagram size={24} />
      </a>
      <a 
        href="https://www.facebook.com/groups/booksandbots" 
        target="_blank" 
        rel="noopener noreferrer" 
        className="p-3 rounded-full bg-primary-light hover:bg-accent-turquoise hover:text-white hover:scale-110 transition-all duration-300 hover:shadow-glow"
      >
        <Facebook size={24} />
      </a>
    </div>
  );
}