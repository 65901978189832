import React from 'react';
import { Mail } from 'lucide-react';
import { PageLayout } from '../layout/PageLayout';
import { Container } from './Container';

export function ComingSoonPage() {
  return (
    <PageLayout>
      <Container className="text-center space-y-8">
        <h1 className="text-4xl font-bold bg-gradient-to-r from-[#1A8F86] to-[#B08E2A] bg-clip-text text-transparent">
          Coming Soon!
        </h1>
        
        <p className="text-xl">
          We are so excited to bring you more information here so stay tuned! Get early access for discounts and exclusive content!!
        </p>

        <div className="mt-8">
          <a
            href="https://link.springboardcrm.com/widget/form/EJUxixPDVicgPkdzZkb7"
            target="_blank"
            rel="noopener noreferrer"
            className="inline-flex items-center gap-3 px-8 py-4 bg-gradient-to-r from-accent-turquoise to-accent-gold text-white rounded-lg font-medium shadow-[0_8px_30px_rgb(0,0,0,0.12)] hover:shadow-[0_8px_30px_rgba(0,0,0,0.25)] transition-all duration-300 hover:scale-[1.02]"
          >
            <Mail size={24} />
            <span>Get Early Access</span>
          </a>
        </div>
      </Container>
    </PageLayout>
  );
}