import React from 'react';
import { Calendar } from 'lucide-react';
import { PageLayout } from '../components/layout/PageLayout';
import { Container } from '../components/common/Container';

export function CustomGPTs() {
  return (
    <PageLayout>
      <Container>
        <div className="flex flex-col items-center gap-6">
          <img
            src="https://storage.googleapis.com/msgsndr/SH9l7Q6IvahKRX1CGcWJ/media/6755c9af988a5fd851a047ea.png"
            alt="GPT Pro Creations Logo"
            className="w-48 h-auto"
          />
          <h1 className="text-4xl font-bold text-center bg-gradient-to-r from-[#1A8F86] to-[#B08E2A] bg-clip-text text-transparent">
            GPT Pro Creations
          </h1>
          
          <div className="mt-4 text-center">
            <p className="text-2xl font-bold text-primary-dark animate-pulse">
              Coming Soon! New Years Drop!
            </p>
          </div>
        </div>
      </Container>
    </PageLayout>
  );
}