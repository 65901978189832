import React from 'react';
import { SpeakingButton } from './SpeakingButton';
import { IntroText } from './IntroText';

export function Header() {
  return (
    <header className="space-y-6">
      <h1 className="text-5xl font-bold text-center bg-gradient-to-r from-accent-turquoise to-accent-gold bg-clip-text text-transparent">
        AI Tech Boss Jenn
      </h1>
      
      <SpeakingButton />
      
      <div className="border-t-2 border-primary-light pt-6">
        <div className="flex flex-col md:flex-row gap-8 items-center">
          <div className="w-full md:w-2/5">
            <div className="rounded-lg overflow-hidden shadow-[0_20px_50px_rgba(8,_112,_184,_0.7)] hover:shadow-[0_20px_60px_rgba(8,_112,_184,_0.8)] transition-all duration-300">
              <img
                src="https://storage.googleapis.com/msgsndr/SH9l7Q6IvahKRX1CGcWJ/media/6755dc26b779b2271d8861e9.jpeg"
                alt="AI Tech Boss Jenn speaking at a professional event with geometric wood panel background"
                className="w-full h-auto"
              />
            </div>
          </div>
          
          <div className="w-full md:w-3/5">
            <IntroText />
          </div>
        </div>
      </div>
    </header>
  );
}