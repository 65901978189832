import React from 'react';
import { BackgroundImage } from '../BackgroundImage';
import { PageContainer } from '../PageContainer';
import { BackButton } from '../common/BackButton';

interface PageLayoutProps {
  children: React.ReactNode;
  showBackButton?: boolean;
}

export function PageLayout({ children, showBackButton = true }: PageLayoutProps) {
  return (
    <>
      <BackgroundImage />
      <PageContainer>
        {showBackButton && <BackButton />}
        {children}
      </PageContainer>
    </>
  );
}