import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { Home } from './pages/Home';
import { About } from './pages/About';
import { CustomGPTs } from './pages/CustomGPTs';
import { GPTShop } from './pages/GPTShop';
import { Speaking } from './pages/Speaking';
import { Essentials } from './pages/Essentials';
import { Collaborative } from './pages/Collaborative';
import { Premium } from './pages/Premium';
import { Publishing } from './pages/Publishing';
import { Webinar } from './pages/Webinar';
import { Checklist } from './pages/Checklist';
import { FreeGPT } from './pages/FreeGPT';

export function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/custom-gpts" element={<CustomGPTs />} />
        <Route path="/gpt-shop" element={<GPTShop />} />
        <Route path="/speaking" element={<Speaking />} />
        <Route path="/essentials" element={<Essentials />} />
        <Route path="/collaborative" element={<Collaborative />} />
        <Route path="/premium" element={<Premium />} />
        <Route path="/publishing" element={<Publishing />} />
        <Route path="/webinar" element={<Webinar />} />
        <Route path="/checklist" element={<Checklist />} />
        <Route path="/free-gpt" element={<FreeGPT />} />
      </Routes>
    </BrowserRouter>
  );
}