import React from 'react';

export function IntroText() {
  return (
    <div className="backdrop-blur-sm bg-white/40 p-6 rounded-xl shadow-[0_20px_50px_rgba(8,_112,_184,_0.7)] transition-all duration-300 hover:shadow-[0_20px_60px_rgba(8,_112,_184,_0.8)]">
      <p className="text-primary-dark leading-relaxed">
        AI Tech Boss Jenn helps entrepreneurs, coaches, and professionals transform their businesses and amplify their impact through expert book publishing and innovative AI solutions. From guiding clients to write and publish their books to implementing AI-driven automation tools, Jenn combines creativity and technology to unlock new opportunities for growth and success.
      </p>
    </div>
  );
}