import React from 'react';

export function ProfessionalInfo() {
  return (
    <div className="prose prose-lg max-w-none">
      <p>
        Jenn Eastmond, widely known as <strong>AI Tech Boss Jenn</strong>, is the founder of <strong>Books and Bots</strong>, a company dedicated to helping entrepreneurs, coaches, and professionals elevate their brands and businesses. Combining her expertise in book publishing and AI-driven automation, Jenn offers a unique approach to growth and impact.
      </p>

      <p>
        Through AI-enhanced book publishing services, Jenn guides clients in writing, publishing, and launching books that establish them as thought leaders. A book is more than just a product—it's a gateway to elevating your brand, gaining speaking engagements, and reaching a larger audience to share your expertise and inspire others.
      </p>

      <p>
        On the tech side, Jenn provides custom AI solutions that transform the way businesses operate. With tools like bespoke AI bots and automation systems, she helps clients streamline tasks, enhance customer interactions, and scale their operations efficiently.
      </p>

      <p>
        Books and bots are essential tools for any entrepreneur or professional looking to stand out, serve more people, and create a lasting legacy. A well-crafted book elevates your authority and opens doors to new opportunities, while AI-driven automation allows you to save time and focus on growth. With Jenn's guidance, you can harness the power of both to maximize your impact, expand your reach, and take your business to the next level.
      </p>
    </div>
  );
}