import React from 'react';
import { Link } from 'react-router-dom';
import { ArrowLeft } from 'lucide-react';

export function BackButton() {
  return (
    <Link to="/" className="inline-flex items-center gap-2 text-gray-600 hover:text-black mb-8">
      <ArrowLeft size={20} />
      <span>Back to Home</span>
    </Link>
  );
}