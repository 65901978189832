import React from 'react';
import { Mic } from 'lucide-react';
import { Link } from 'react-router-dom';

export function SpeakingButton() {
  return (
    <Link
      to="/speaking"
      className="block w-full bg-gradient-to-r from-primary-light to-accent-cream text-primary-dark border border-primary-dark rounded-lg px-6 py-4 text-center font-medium hover:scale-[1.02] transition-all duration-300 hover:shadow-[0_8px_30px_rgba(0,0,0,0.25)] flex items-center justify-center gap-3 backdrop-blur-sm shadow-[0_8px_30px_rgb(0,0,0,0.12)]"
    >
      <Mic size={24} />
      <span>Book AI Tech Boss Jenn for your next Event</span>
    </Link>
  );
}