import React from 'react';

export function AboutHeader() {
  return (
    <div className="text-center space-y-4">
      <h1 className="text-4xl font-bold">About AI Tech Boss Jenn</h1>
      <div className="space-y-2">
        <p className="text-xl font-semibold text-gray-700">AI & TECH CONSULTANT + STRATEGIST</p>
        <p className="text-lg text-gray-600">SPEAKER | AUTHOR | TEACHER | BOT INNOVATOR</p>
      </div>
    </div>
  );
}