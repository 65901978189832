import React from 'react';
import { PageLayout } from '../components/layout/PageLayout';
import { Container } from '../components/common/Container';
import { AboutHeader } from '../components/about/AboutHeader';
import { ProfessionalInfo } from '../components/about/ProfessionalInfo';
import { PersonalInfo } from '../components/about/PersonalInfo';

export function About() {
  return (
    <PageLayout>
      <Container>
        <div className="space-y-8">
          <AboutHeader />
          <ProfessionalInfo />
          <PersonalInfo />
        </div>
      </Container>
    </PageLayout>
  );
}