import React, { useEffect, useRef } from 'react';

export function VideoPlayer() {
  const videoRef = useRef<HTMLVideoElement>(null);

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.play().catch(() => {
        // Autoplay failed, which is fine - user can play manually
      });
    }
  }, []);

  return (
    <div className="relative rounded-xl overflow-hidden shadow-[0_20px_50px_rgba(8,_112,_184,_0.7)]">
      <video
        ref={videoRef}
        className="w-full h-auto rounded-xl"
        playsInline
        autoPlay
        muted
        loop
        controlsList="nodownload nofullscreen"
        disablePictureInPicture
        controls
      >
        <source
          src="https://storage.googleapis.com/msgsndr/SH9l7Q6IvahKRX1CGcWJ/media/675a6fe753bb956c2fae8100.mp4"
          type="video/mp4"
        />
        Your browser does not support the video tag.
      </video>
    </div>
  );
}